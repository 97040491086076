<template>
  <b-card>
    <b-table
      ref="table"
      :items="getDonations"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No donations found"
      show-empty
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </b-card>
</template>
<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'ProjectDonations',
  components: { Pagination },
  data() {
    return {
      tableColumns: [
        { key: 'id' },
        { key: 'donorName' },
        { key: 'amount' },
        { key: 'paymentMethod' },
        { key: 'installmentsNumber' },
        { key: 'createdAt', label: 'Transaction Date time' },
      ],
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getDonations() {
      return this.$payment.get('/internalops/fundraising/transactions', {
        params: {
          fundraisingId: this.$route.params.id,
        },
      }).then(res => {
        const donations = res.data.data
        this.pagination.totalRows = res.data.pagination.total
        return donations || []
      }).catch(() => [])
    },
  },
}
</script>
<style lang="scss">

</style>
